<template>
  <div>
    <simple-table :columns="columns">
      <template #tableBody>
        <tr>
          <td
            v-if="!rows.records || rows.records.length == 0"
            :colspan="columns.length"
            align="center"
          >
            No records found.
          </td>
        </tr>
        <tr v-for="(row, index) in rows.records" :key="index">
          <td>{{ row.id }}</td>
          <td>
            <strong>{{ row.title }}</strong>
            <span
              :class="[
                'd-block',
                'px-0',
                row.is_published ? 'clr-active' : 'clr-inactive',
              ]"
              >{{ row.is_published_name }}</span
            >
          </td>
          <td>
            <span class="d-block" v-if="row.is_published"
              >Published At : <strong>{{ row.publication_date }}</strong></span
            >
            <span class="d-block"
              >Created At :<strong>{{ row.created_at }}</strong></span
            >
          </td>
          <td>
            <div>
              <a
                :href="file.path"
                target="_blank"
                class="route-link d-block"
                v-for="(file, index) in row.files"
                :key="index"
                >{{ file.filename }}</a
              >
            </div>
          </td>
          <td>
            <span
              class="k-feed-tag"
              v-for="(tag, i) in row.tags"
              :key="i"
              >{{ tag.name }}</span
            >
          </td>
          <td>
            <div>
              <span
                class="route-link d-block cursor-pointer"
                v-for="(type, i) in row.publication_types"
                :key="i"
                @click="openUserModal(row, type)"
                >{{ type }}</span
              >
            </div>
          </td>
          <td>
            <div class="action-icons">
              <custom-button
                title="Edit Details"
                iconClass="fas fa-pen"
                @onClicked="editRow(row)"
              />
              <custom-button
                :title="row.is_published ? 'Unpublish' : 'Publish'"
                :iconClass="
                  row.is_published
                    ? 'fas fa-calendar-minus'
                    : 'fas fa-calendar-check'
                "
                @onClicked="handlePublication(row)"
              />
              <custom-button
                title="Delete"
                iconClass="fas fa-trash-alt"
                @onClicked="deleteRow(row)"
              />
            </div>
          </td>
        </tr>
      </template>
    </simple-table>
    <publication-modal :row.sync="row" @onSuccess="onSuccess" />
  </div>
</template>

<script>
import Api from "@/resource/Api";
import PublicationModal from "./PublicationModal.vue";
import helper from "@/resource/Helper";
export default {
  props: {
    rows: {
      required: true,
      default: () => {},
    },
  },
  components: {
    PublicationModal,
  },
  data: () => ({
    columns: [
      {
        label: "Id",
      },
      {
        label: "Title",
      },
      {
        label: "Dates",
      },
      {
        label: "Files",
      },
      {
        label: "Tags",
      },
      {
        label: "Publication",
      },
      {
        label: "Action",
      },
    ],
    row: null,
  }),
  methods: {
    deleteRow(row) {
      const url = `v2/knowledge-feed/${row.id}/delete`;
      helper
        .confirmDialog(
          `Delete ${row.title}`,
          "Do you want to delete knowledge feed ?"
        )
        .then((confirm) => {
          if (!confirm) {
            return false;
          }
          let loader = this.$loading.show();
          Api.delete(url)
            .then((res) => {
              let response = res.data.message;
              this.$notify(response);
              this.$emit("onSuccess");
            })
            .catch((err) => {
              this.$notify(err.response.data.message, "error");
            })
            .finally(() => {
              loader.hide();
            });
        });
    },
    openModal(row, modalId) {
      this.row = row;
      this.$bvModal.show(modalId);
    },
    handlePublication(row) {
      if (row.is_published) {
        helper
          .confirmDialog(
            row.title,
            "Do you want to unpublish this knowledge feed"
          )
          .then((confirm) => {
            if (!confirm) {
              return false;
            }
            let formData = {
              id: row.id,
              is_published: false,
            };
            this.submitPublication(formData);
          });
      } else {
        this.openModal(row, "publishModal");
      }
    },
    submitPublication(formData) {
      let loader = this.$loading.show();
      let form = {
        ...formData,
      };
      Api.put("v2/knowledge-feed/publish", form)
        .then((res) => {
          this.$emit("onSuccess");
          this.$notify(res.data.message);
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          loader.hide();
        });
    },
    onSuccess() {
      this.$emit("onSuccess");
    },
    editRow(row) {
      this.$emit("onEdit", row);
    },
    openUserModal(row, userType) {
      this.$emit("openUserModal",row ,userType);
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>

