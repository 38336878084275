var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('simple-table', {
    attrs: {
      "columns": _vm.columns
    },
    scopedSlots: _vm._u([{
      key: "tableBody",
      fn: function fn() {
        return [_c('tr', [!_vm.rows.records || _vm.rows.records.length == 0 ? _c('td', {
          attrs: {
            "colspan": _vm.columns.length,
            "align": "center"
          }
        }, [_vm._v(" No records found. ")]) : _vm._e()]), _vm._l(_vm.rows.records, function (row, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_vm._v(_vm._s(row.id))]), _c('td', [_c('strong', [_vm._v(_vm._s(row.title))]), _c('span', {
            class: ['d-block', 'px-0', row.is_published ? 'clr-active' : 'clr-inactive']
          }, [_vm._v(_vm._s(row.is_published_name))])]), _c('td', [row.is_published ? _c('span', {
            staticClass: "d-block"
          }, [_vm._v("Published At : "), _c('strong', [_vm._v(_vm._s(row.publication_date))])]) : _vm._e(), _c('span', {
            staticClass: "d-block"
          }, [_vm._v("Created At :"), _c('strong', [_vm._v(_vm._s(row.created_at))])])]), _c('td', [_c('div', _vm._l(row.files, function (file, index) {
            return _c('a', {
              key: index,
              staticClass: "route-link d-block",
              attrs: {
                "href": file.path,
                "target": "_blank"
              }
            }, [_vm._v(_vm._s(file.filename))]);
          }), 0)]), _c('td', _vm._l(row.tags, function (tag, i) {
            return _c('span', {
              key: i,
              staticClass: "k-feed-tag"
            }, [_vm._v(_vm._s(tag.name))]);
          }), 0), _c('td', [_c('div', _vm._l(row.publication_types, function (type, i) {
            return _c('span', {
              key: i,
              staticClass: "route-link d-block cursor-pointer",
              on: {
                "click": function click($event) {
                  return _vm.openUserModal(row, type);
                }
              }
            }, [_vm._v(_vm._s(type))]);
          }), 0)]), _c('td', [_c('div', {
            staticClass: "action-icons"
          }, [_c('custom-button', {
            attrs: {
              "title": "Edit Details",
              "iconClass": "fas fa-pen"
            },
            on: {
              "onClicked": function onClicked($event) {
                return _vm.editRow(row);
              }
            }
          }), _c('custom-button', {
            attrs: {
              "title": row.is_published ? 'Unpublish' : 'Publish',
              "iconClass": row.is_published ? 'fas fa-calendar-minus' : 'fas fa-calendar-check'
            },
            on: {
              "onClicked": function onClicked($event) {
                return _vm.handlePublication(row);
              }
            }
          }), _c('custom-button', {
            attrs: {
              "title": "Delete",
              "iconClass": "fas fa-trash-alt"
            },
            on: {
              "onClicked": function onClicked($event) {
                return _vm.deleteRow(row);
              }
            }
          })], 1)])]);
        })];
      },
      proxy: true
    }])
  }), _c('publication-modal', {
    attrs: {
      "row": _vm.row
    },
    on: {
      "update:row": function updateRow($event) {
        _vm.row = $event;
      },
      "onSuccess": _vm.onSuccess
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }