<template>
  <div class="k-feed-popup">
    <b-modal
      id="knowledgeFeed"
      centered
      size="lg"
      v-model="show"
      no-close-on-backdrop
      @shown="getUsers"
    >
      <template #modal-header>
        <div class="k-feed-popup-header text-center">
          <h4>{{ isEdit ? "Edit" : "Add" }} Knowledge Feed</h4>
          <b-button variant="close-btn" @click="closeModal">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div class="k-feed-popup-form">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">
              <input-text
                label-name="Title"
                label-for="title"
                :vmodel.sync="form.title"
                form-group-class="form-group mb-3"
                input-class="custom-input"
                :rules="{ required: true }"
                :api-errors="apiErrors"
              />

              <div class="form-group mb-4 mt-3">
                <validation-provider
                  name="Message"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for=""
                    >Description <span class="required-indc">*</span></label
                  >
                  <quill-editor
                    v-model="form.description"
                    :options="editorOption"
                    height="300"
                  />
                  <div class="invalid-block invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>

              <div class="col-md-12 mb-3">
                    <label class="label-txt up-load mt-2"
                      >Upload Document
                    </label>
                    <file-upload
                      labelName="Upload Files"
                      label-for="files"
                      :vmodel.sync="form.files"
                      :error.sync="error"
                      :remove="remove"
                      formGroupClass="mt-4 mb-1 cursor-pointer"
                    >
                </file-upload>
                  </div>
                  <div>
                    <div
                    class="my-2"
                    v-if="isEdit && form.file_lists && form.file_lists.length"
                  >
                    <ul class="upload-item">
                      <li
                        class=""
                        v-for="(file, index) in form.file_lists"
                        :key="index"
                      >
                        <a
                          :href="file.path"
                          target="_blank"
                          class="clr-active"
                          >{{ file.filename }}</a
                        ><custom-button
                          title="Delete"
                          iconClass="fas fa-trash-alt"
                          className="ms-2"
                          @onClicked="deleteFile(file.id, index)"
                        />
                      </li>
                    </ul>
                  </div>
                  </div>

              <!-- <input-file-multiple
                labelName="Upload Files"
                label-for="files"
                :vmodel.sync="form.files"
                :rules="{ required: false }"
                form-group-class="form-group my-3"
                :api-errors="apiErrors"
                validation-label="files"
              >
                <template #labelMessage>
                  <span class="route-link ms-1">(File Size 10Mb max)</span>
                </template>
                <template #uploadedFiles>
                  <div
                    class="my-2"
                    v-if="isEdit && form.file_lists && form.file_lists.length"
                  >
                    <ul class="upload-item">
                      <li
                        class=""
                        v-for="(file, index) in form.file_lists"
                        :key="index"
                      >
                        <a
                          :href="file.path"
                          target="_blank"
                          class="clr-active"
                          >{{ file.filename }}</a
                        ><custom-button
                          title="Delete"
                          iconClass="fas fa-trash-alt"
                          className="ms-2"
                          @onClicked="deleteFile(file.id, index)"
                        />
                      </li>
                    </ul>
                  </div>
                  <div class="my-2" v-if="form.files && form.files.length">
                    <ul class="upload-item">
                      <li
                        class=""
                        v-for="(file, index) in form.files"
                        :key="index"
                      >
                        <a href="#" target="_blank" class="clr-active">{{
                          file.name
                        }}</a> -->
                        <!-- <custom-button
                          title="Delete"
                          iconClass="fas fa-trash-alt"
                          className="ms-2"
                          @onClicked="form.files.splice(index, 1)"
                        /> -->
                        <!-- <custom-button
                          title="Delete"
                          iconClass="fas fa-trash-alt"
                          className="ms-2"
                          @onClicked="delFile(index)"
                        />
                      </li>
                    </ul>
                  </div>
                </template>
                <template #customErrors>
                  <span class="file-error d-block" v-if="computedFileError">{{
                    computedFileError
                  }}</span>
                </template>
              </input-file-multiple> -->
              <div class="form-group row">
                <input-radio
                  label="Publication"
                  :vmodel.sync="form.is_published"
                  :api-errors="apiErrors"
                  api-error-key="is_published"
                  :options="publicationOptions"
                  :rules="{ required: true }"
                  form-group-class="col-xxl-6 col-lg-6 col-md-6 mb-3"
                />
                <input-date-picker
                  v-if="form.is_published == '1'"
                  :vmodel.sync="form.published_date"
                  :rules="{ required: true }"
                  label="Publication Date"
                  :api-errors="apiErrors"
                  api-error-key="published_date"
                  date-format="MM/DD/YYYY"
                  disable-date-type="disable-upto-yesterday"
                  form-group-class="col-xxl-6 col-lg-6 col-md-6 mb-3"
                />
              </div>

              <div class="form-group row">
                <input-multi-select-tags
                  :vmodel.sync="form.publication_types"
                  label-name="Whom To Publish"
                  :options="
                    userOptions.length
                      ? userOptions.map((item) => item.value)
                      : []
                  "
                  :custom-label="
                    (option) =>
                      userOptions.find((data) => data.value == option).label
                  "
                  :rules="{ required: true }"
                  form-group-class="col-xxl-12 col-lg-12 col-md-12 mb-3"
                  placeholder="Chose Whom To Publish"
                  label-key="label"
                  track-by="value"
                  :options-list="userOptions"
                  :api-errors="apiErrors"
                  api-error-key="publication_types"
                />
                <div class="mb-2" v-if="isPublicationTypeValid">
                  <label>Platform List</label>
                  <select
                    v-model="form.platform"
                    class="price-option"
                    :class="[
                      form.platform ? 'selected-color' : 'unselected-color',
                    ]"
                  >
                    <option value="" disabled>Select Platform</option>
                    <option
                      :value="platform"
                      v-for="(platform, index) in platformList"
                      :key="index"
                    >
                      {{ platform }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div
                  class="col-md-12 mb-3"
                  v-if="form.publication_types.includes('REP')"
                >
                  <!-- <input-multi-select-tags
                    :vmodel.sync="form.agents"
                    label-name="Rep List"
                    :options="
                      repList && repList.length
                        ? repList.map((item) => item.id)
                        : []
                    "
                    :custom-label="
                      repList && repList.length
                        ? (option) =>
                            repList.find((data) => data.id == option).name
                        : () => {}
                    "
                    :rules="{ required: true }"
                    placeholder="Select agents"
                    :options-limit="5000"
                    :is-loading="isRepLoading"
                    @search="(q) => searchUser(q, 'rep')"
                    :options-list="repList"
                    label-key="name"
                    :show-selections="true"
                  /> -->

                  <custom-button
                    :title="
                      sendRepMessageSelectedReps
                        ? `${sendRepMessageSelectedReps.length} Rep Selected`
                        : `Select Rep`
                    "
                    haveText
                    @onClicked="openAddRepModal"
                    class="btn univ-btn"
                  />
                </div>
                <div
                  class="col-md-12 mb-3"
                  v-if="form.publication_types.includes('GROUP')"
                >
                  <input-multi-select-tags
                    :vmodel.sync="form.groups"
                    label-name="Group List"
                    :options="
                      groupList && groupList.length
                        ? groupList.map((item) => item.id)
                        : []
                    "
                    :custom-label="
                      groupList && groupList.length
                        ? (option) =>
                            groupList.find((data) => data.id == option).name
                        : () => {}
                    "
                    :rules="{ required: true }"
                    placeholder="Select Groups"
                    :active-data="groupList"
                    :options-limit="5000"
                    :is-loading="isGroupLoading"
                    @search="(q) => searchUser(q, 'group')"
                    :options-list="groupList"
                    label-key="name"
                    :show-selections="true"
                  />
                </div>
                <div
                  class="col-md-12 mb-3"
                  v-if="form.publication_types.includes('MEMBER')"
                >
                  <input-multi-select-tags
                    :vmodel.sync="form.members"
                    label-name="Member List"
                    :options="
                      memberList && memberList.length
                        ? memberList.map((item) => item.id)
                        : []
                    "
                    :custom-label="
                      memberList && memberList.length
                        ? (option) =>
                            memberList.find((data) => data.id == option).name
                        : () => {}
                    "
                    :rules="{ required: true }"
                    placeholder="Select Members"
                    :active-data="memberList"
                    :is-loading="isMemberLoading"
                    :options-limit="5000"
                    @search="(q) => searchUser(q, 'member')"
                    :options-list="memberList"
                    label-key="name"
                    :show-selections="true"
                  />
                </div>
              </div>

              <input-multi-select-tags
                :vmodel.sync="form.tags"
                label-name="Tags"
                :options="tags.length ? tags.map((item) => item.id) : []"
                :custom-label="
                  (option) => tags.find((data) => data.id == option).name
                "
                :rules="{ required: true }"
                form-group-class="form-group mb-3"
                placeholder="Select Or Add Tag"
                label-key="name"
                track-by="id"
                :options-list="tags"
                :is-taggable="true"
                @onAddTag="createTag"
                :is-loading="isTagLoading"
                :api-errors="apiErrors"
              />
              <div class="form-group mb-3 pt-3 text-center">
                <button class="addBtn">
                  {{ isEdit ? "Update" : "Add" }} Knowledge Feed
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </b-container>
      <rep-tree-modal
        :downlineList="downlineList"
        @onCloseModal="closeRepTree"
      />
    </b-modal>
  </div>
</template>
<script>
import RepTreeModal from "@/components/RepTree/RepTreeModal.vue";
import InputRadio from "@/components/form/InputRadio.vue";
import InputMultiSelectTags from "@/components/form/InputMultiSelectTags.vue";
import InputDatePicker from "@/components/form/InputDatePicker.vue";
import moment from "moment";
import Api from "@/resource/Api";
import { mapGetters } from "vuex";
const form = {
  title: "",
  description: "",
  files: null,
  is_published: "",
  tags: [],
  publication_types: [],
  published_date: "",
  agents: [],
  groups: [],
  members: [],
  file_lists: [],
  deleted_files: [],
  platform: ""
};

const options = {
  //value is tupple
  agents: ["repList", "is_all_agents"],
  members: ["memberList", "is_all_members"],
  groups: ["groupList", "is_all_groups"],
};

export default {
  components: {
    InputRadio,
    InputMultiSelectTags,
    InputDatePicker,
    RepTreeModal,
  },
  props: {
    row: {
      required: true,
    },
    isEdit: {
      type: Boolean,
      defalt: false,
    },
  },
  data: () => ({
    remove: false,
    error: false,
    show: false,
    form: { ...form },
    apiErrors: null,
    publicationOptions: [
      {
        label: "Yes",
        value: "1",
      },
      {
        label: "No",
        value: "0",
      },
    ],
    userOptions: [
      {
        label: "Rep",
        value: "REP",
      },
      {
        label: "Group",
        value: "GROUP",
      },
      {
        label: "Member",
        value: "MEMBER",
      },
    ],
    repList: [],
    groupList: [],
    memberList: [],
    tags: [],
    isTagLoading: false,
    isRepLoading: false,
    isGroupLoading: false,
    isMemberLoading: false,
    editorOption: {
      modules: {
        toolbar: [
          ["bold", "italic", "underline"],
          ["blockquote", "code-block"],
          [{ header: 1 }, { header: 2 }, { header: 3 }],
          [{ list: "ordered" }, { list: "bullet" }],

          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],
          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ["clean"],
          ["link", "image"],
        ],
        imageResize: {
          //Add
          displayStyles: {
            //Add
            backgroundColor: "black",
            border: "none",
            color: "white",
          },
          modules: ["Resize", "DisplaySize", "Toolbar"],
        },
      },
      placeholder: "Enter content...",
    },
    isSearched: {
      agents: false,
      groups: false,
      members: false,
    },
    downlineList: {},
    repId: null,
    platformList: [],
    isPublicationTypeValid: false,
  }),
  computed: {
    ...mapGetters({
      sendRepMessageSelectedReps: "sendRepMessageSelectedReps",
      sendRepMessageCheckedRep: "sendRepMessageCheckedRep",
    }),
    computedFileError() {
      if (!this.apiErrors) {
        return "";
      }
      for (const errorKey in this.apiErrors) {
        console.log(errorKey, typeof errorKey);
        if (typeof errorKey == "string" && errorKey.indexOf("files.") !== -1) {
          return this.apiErrors[errorKey][0];
        }
      }
      return "";
    },
    isValidPublicationType() {
      return (
        this.form.publication_types.includes("REP") ||
        this.form.publication_types.includes("GROUP")
      );
    },
  },
  methods: {
    delFile(index) {
      let fileArray = [];
      this.form.files.forEach((file, i) => {
        if (index != i) {
          fileArray.push(file);
        }
      });
      this.form.files = fileArray;
    },
    deleteFile(id, index) {
      this.form.deleted_files.push(id);
      this.form.file_lists.splice(index, 1);
    },
    getConfigurations() {
      let loader = this.$loading.show();
      let url = `v2/list-active-plans?agent_id=${this.repId}`;
      Api.get(url)
        .then((res) => {
          this.platformList = [];
          this.platformList = res.data.data.website_list;
        })
        .catch(() => {})
        .finally(() => {
          loader.hide();
        });
    },
    closeRepTree() {
      if (this.isEdit && this.row.agents && this.row.agents.length) {
        this.$store.commit(
          "setSendRepMessageSelectedReps",
          this.row.agents.filter((item) => item.id).map((item) => item.id)
        );
      }
    },
    openAddRepModal() {
      this.$bvModal.show("repTreeModal");
      this.$emit("onGetDownlineCheckList", this.downlineList);
    },
    async getDownlineList() {
      this.$store.dispatch("getRepInfo");
      let agentId = this.$store.getters.rep.id_for_tree
        ? this.$store.getters.rep.id_for_tree
        : this.$store.getters.repId;
      let url = `v2/get-rep-tree?agent_id=${agentId}`;
      let loader = this.$loading.show();
      await Api.get(url)
        .then((res) => {
          this.downlineList = res.data.data;
        })
        .catch(() => (this.downlineList = {}))
        .finally(() => {
          loader.hide();
        });
    },
    async getTagList(tagId = null) {
      this.isTagLoading = true;
      await Api.get("v2/tag/list")
        .then((res) => {
          this.tags = res.data.data;
          if (tagId) {
            this.form.tags.push(tagId);
          }
        })
        .catch(() => (this.tags = []))
        .finally(() => (this.isTagLoading = false));
    },
    closeModal() {
      this.$emit("update:isEdit", false);
      this.$bvModal.hide("knowledgeFeed");
      this.form = { ...form };
      this.repList = [];
      this.groupList = [];
      this.memberList = [];
      this.$store.commit("setSendRepMessageSelectedReps", []);
    },
    getFormattedDate(date, format) {
      return moment(date).format(format);
    },
    errorResponse(error) {
      if (!error.response) {
        this.$notify("Something Went Wrong", "error");
      }
      if (error.response.status == 422) {
        this.apiErrors = { ...error.response.data.errors };
        setTimeout(() => {
          this.apiErrors = null;
        }, 5000);
      } else {
        this.$notify(error.response.data.message, "error");
      }
    },
    checkPublicationType (publication_types) {
      if (publication_types.length === 1 && publication_types[0] === "MEMBER") {
        return true;
      } else {
        return false;
      }
    },
    submit() {
      if (this.form.publication_types.includes("REP")) {
        this.form.agents = this.$store.getters.sendRepMessageSelectedReps;
      }
      if (this.checkPublicationType(this.form.publication_types)) {
        this.form.platform = ''
      }
      let form = this.isEdit
        ? { ...this.form, id: this.row.id, _method: "PUT" }
        : { ...this.form };
      const publicationValues = {
        rep: "agents",
        group: "groups",
        member: "members",
      };
      Object.keys(publicationValues).forEach((k) => {
        if (!form.publication_types.includes(k.toUpperCase())) {
          delete form[publicationValues[k]];
        }
      });
      if (!form.files) {
        delete form.files;
      }
      if (form.is_published === 0) {
        delete form.published_date;
      }
      let formData = new FormData();
      form.published_date = form.published_date
        ? this.getFormattedDate(form.published_date, "YYYY-MM-DD")
        : "";
      for (const [key, value] of Object.entries(form)) {
        if (Array.isArray(value)) {
          form[key].forEach((data, index) => {
            formData.append(`${key}[${index}]`, data);
          });
        } else {
          formData.append(key, value);
        }
      }
      if (this.form.deleted_files && this.form.deleted_files.length <= 0) {
        formData.append("deleted_files[0]", null);
      }
      let url = `v2/knowledge-feed/${this.isEdit ? "update" : "store"}`;
      this.save(url, formData);
    },
    save(url, formData) {
      let loader = this.$loading.show();
      Api.post(url, formData)
        .then((res) => {
          this.$notify(res.data.message);
          this.$emit("onSuccess");
          this.closeModal();
        })
        .catch((err) => {
          this.errorResponse(err);
        })
        .finally(() => {
          loader.hide();
        });
    },
    createTag(name) {
      if (!name) {
        return false;
      }
      this.isTagLoading = true;
      Api.post("v2/tag/store", { name: name })
        .then((res) => {
          const data = res.data.data;
          this.getTagList(data.id);
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isTagLoading = false;
        });
    },
    getRepList(search = "") {
      this.isRepLoading = true;
      this.isSearched.agents = search.length ? true : false;
      Api.get(
        `v2/get-rep-downline?${search ? `rep_name=${search}` : "size=100"}`
      )
        .then((res) => {
          const data = res.data.data;
          let repList = [...this.repList];
          // allow dropdowns to have unique ids only
          const ids = [...new Set(repList.map((data) => data.id))];
          data.forEach((item) => {
            if (!ids.includes(item.id)) {
              repList.push({
                id: item.id,
                name: `${item.first_name} ${item.last_name} (${item.agent_code})`,
              });
            }
          });
          // preserve searched items on query
          if (this.repList.length && this.repList[0].id === "all") {
            this.repList = [...repList];
          } else {
            this.repList = [{ id: "all", name: "All" }, ...repList];
          }
        })
        .catch(() => {
          this.repList = [];
        })
        .finally(() => {
          this.isRepLoading = false;
        });
    },
    getGroupList(search = "") {
      this.isGroupLoading = true;
      this.isSearched.groups = search.length ? true : false;
      Api.get(
        `v2/get-rep-group-list?${search ? `group_name=${search}` : "size=100"}`
      )
        .then((res) => {
          const data = res.data.data;
          let groupList = [];
          groupList = [...this.groupList];
          const ids = [...new Set(groupList.map((data) => data.id))];
          data.forEach((item) => {
            if (!ids.includes(item.group_id)) {
              groupList.push({
                id: item.group_id,
                name: `${item.group_name} (${item.group_code})`,
              });
            }
          });
          // preserve searched items on query
          if (this.groupList.length && this.groupList[0].id === "all") {
            this.groupList = [...groupList];
          } else {
            this.groupList = [{ id: "all", name: "All" }, ...groupList];
          }
        })
        .catch(() => {
          this.groupList = [];
        })
        .finally(() => {
          this.isGroupLoading = false;
        });
    },
    getMemberList(search = "") {
      this.isMemberLoading = true;
      this.isSearched.members = search.length ? true : false;
      Api.get(`v2/get-members?${search ? `search=${search}` : "page_size=100"}`)
        .then((res) => {
          const data = res.data.records;
          let members = [];
          members = [...this.memberList];
          const ids = [...new Set(members.map((data) => data.id))];
          data.forEach((item) => {
            if (!ids.includes(item.user_id)) {
              members.push({
                id: item.user_id,
                name: `${item.first_name} ${item.last_name}`,
              });
            }
          });
          // preserve searched items on query
          if (this.memberList.length && this.memberList[0].id === "all") {
            this.memberList = [...members];
          } else {
            this.memberList = [{ id: "all", name: "All" }, ...members];
          }
        })
        .catch(() => {
          this.memberList = [];
        })
        .finally(() => {
          this.isMemberLoading = false;
        });
    },
    getUsers() {
      // this.getRepList();
      this.getMemberList();
      this.getGroupList();
      this.getDownlineList();
    },
    searchUser(query, type) {
      if (query.length < 3) {
        return false;
      }
      switch (type) {
        case "rep":
          this.getRepList(query);
          break;
        case "group":
          this.getGroupList(query);
          break;
        case "member":
          this.getMemberList(query);
          break;
        default:
          break;
      }
    },
    handleAllOption(userType) {
      /*console.log(
        this.row[options[userType][1]],
        options[userType][1],
        this[options[userType][0]]
      );*/
      if (
        this.row[options[userType][1]] == 1 &&
        this[options[userType][0]].length
      ) {
        this.form[userType] = this[options[userType][0]].map((item) => item.id);
      }
    },
    updateUserList(type, useCode = true) {
      if (this.row[type].length && !this.isSearched[type]) {
        this.row[type].forEach((item) => {
          this[options[type][0]].push({
            id: item.id,
            name: useCode ? `${item.name} (${item.code})` : `${item.name}`,
          });
        });
      }
    },
    updateFormValues(row) {
      this.form = {
        title: row.title,
        description: row.description,
        files: [],
        file_lists: row.files,
        is_published: row.is_published,
        tags: row.tags.map((item) => item.id),
        publication_types: row.publication_types.map((item) => item),
        published_date: row.publication_date,
        agents: row.agents.map((item) => item.id),
        groups: row.groups.map((item) => item.id),
        members: row.members.map((item) => item.id),
        deleted_files: [],
        platform: row.platform
      };
    },
  },
  created() {
    this.getTagList();
    this.$store.commit("setSendRepMessageSelectedReps", []);
    this.$store.commit("setSendRepMessageCheckedRep", false);
    this.repId = this.$store.getters.repId;
    this.getConfigurations("defaultLoad");
  },
  watch: {
    row: {
      handler: function (row) {
        if (!row || !Object.keys(row).length) {
          return;
        }
        this.updateFormValues(this.row);
        if (row.agents && row.agents.length) {
          this.$store.commit(
            "setSendRepMessageSelectedReps",
            row.agents.filter((item) => item.id).map((item) => item.id)
          );
        }
        Object.keys(options).forEach((user) => {
          this.updateUserList(user, user == "members" ? false : true);
          this.handleAllOption(user);
        });
      },
    },
    repList: {
      handler: function (items) {
        // disable update on watch if search is true
        if (items.length && !this.isSearched.agents) {
          this.handleAllOption("agents");
        }
      },
    },
    groupList: {
      handler: function (items) {
        if (items.length && !this.isSearched.groups) {
          this.handleAllOption("groups");
        }
      },
    },
    memberList: {
      handler: function (items) {
        if (items.length && !this.isSearched.members) {
          this.handleAllOption("members");
        }
      },
    },
    isValidPublicationType(newValue) {
      this.isPublicationTypeValid = newValue;
    },
  },
};
</script>
<style scoped>
.upload-item {
  background: #f3f7fa !important;
  margin: 0 !important;
  padding: 0;
  border-radius: 6px !important;
}
.upload-item li {
  background: #f3f7fa !important;
  list-style: none;
  font-size: 14px !important;
  padding: 0.5rem 0.8rem;
  border-radius: 6px;
}
.file-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
</style>
<style>
.mx-datepicker {
  width: 100%;
}

.modal-header {
  justify-content: center;
  border: 0;
  padding: 25px 0;
  position: relative;
}
.modal-footer {
  display: none;
}
.k-feed-popup-header button {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #fff;
  color: #000;
  border: 0;
  box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
  border-radius: 60px;
  font-size: 18px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.modal-content {
  border: 0;
  border-radius: 15px;
}
.k-feed-popup-header h4 {
  font-size: 20px;
  color: #2c3f58;
  font-weight: 600;
  margin: 0;
}
.addBtn {
  background: #03c3ec;
  border-radius: 6px;
  color: #fff;
  outline: none;
  border: 0;
  padding: 10px 0;
  margin-top: 10px;
  transition: all 0.6s ease;
  padding: 10px 50px;
}
.addBtn:hover {
  background: #455c75;
  transition: all ease 0.4s;
}
div.multiselect {
  margin-left: 0 !important;
  width: 100% !important;
}
.ql-container {
  min-height: 220px;
}
input[type="file"] {
  color: transparent;
}

.price-option {
  font-size: 14px;
}
.unselected-color {
  color: #c0c0c0;
}

.selected-color {
  color: #212529;
}

.price-option option {
  color: #212529;
}
.price-option option:hover {
  background-color: red !important;
  color: white;
}
</style>
