var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper k-feed-wrapper"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('div', {
    staticClass: "page-title"
  }, [_c('div', {
    staticClass: "titleBtn"
  }, [_c('h1', [_vm._v("Manage Knowledge Feeds")]), _c('button', {
    staticClass: "k-feed-add-btn",
    on: {
      "click": _vm.openForm
    }
  }, [_c('i', {
    staticClass: "fa fa-plus",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" Add Knowledge Feed ")])])]), _c('div', {
    staticClass: "k-feed-card"
  }, [_c('div', {
    staticClass: "filter-field text-end mb-3"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filters.query,
      expression: "filters.query"
    }],
    staticClass: "custom-input",
    attrs: {
      "type": "text",
      "placeholder": "Search (by title , tag)"
    },
    domProps: {
      "value": _vm.filters.query
    },
    on: {
      "keyup": _vm.filterList,
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.filters, "query", $event.target.value);
      }
    }
  })]), _c('knowledge-feed-list', {
    attrs: {
      "rows": _vm.rows
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      },
      "onSuccess": function onSuccess($event) {
        return _vm.getKnowledgeFeed(_vm.filters);
      },
      "onEdit": _vm.onEdit,
      "openUserModal": _vm.openUserModal
    }
  }), _c('pagination', {
    attrs: {
      "rows": _vm.rows
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      }
    }
  })], 1)], 1), _c('knowledge-feed-form', {
    attrs: {
      "row": _vm.row,
      "is-edit": _vm.isEdit
    },
    on: {
      "onSuccess": function onSuccess($event) {
        return _vm.getKnowledgeFeed(_vm.filters);
      },
      "update:row": function updateRow($event) {
        _vm.row = $event;
      },
      "update:isEdit": function updateIsEdit($event) {
        _vm.isEdit = $event;
      },
      "update:is-edit": function updateIsEdit($event) {
        _vm.isEdit = $event;
      }
    }
  }), _c('knowledge-feed-user', {
    attrs: {
      "user-row": _vm.userRow,
      "user-list": _vm.userList,
      "user-type": _vm.userType
    },
    on: {
      "update:userRow": function updateUserRow($event) {
        _vm.userRow = $event;
      },
      "update:user-row": function updateUserRow($event) {
        _vm.userRow = $event;
      },
      "update:userList": function updateUserList($event) {
        _vm.userList = $event;
      },
      "update:user-list": function updateUserList($event) {
        _vm.userList = $event;
      },
      "update:userType": function updateUserType($event) {
        _vm.userType = $event;
      },
      "update:user-type": function updateUserType($event) {
        _vm.userType = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "pagination",
      fn: function fn() {
        return [_vm.userList ? _c('pagination', {
          staticClass: "mt-2",
          attrs: {
            "rows": _vm.userList
          },
          on: {
            "update:rows": function updateRows($event) {
              _vm.userList = $event;
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }