var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "k-feed-popup"
  }, [_c('b-modal', {
    attrs: {
      "id": "knowledgeFeed",
      "centered": "",
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    on: {
      "shown": _vm.getUsers
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "k-feed-popup-header text-center"
        }, [_c('h4', [_vm._v(_vm._s(_vm.isEdit ? "Edit" : "Add") + " Knowledge Feed")]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "k-feed-popup-form"
  }, [_c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.submit);
            }
          }
        }, [_c('input-text', {
          attrs: {
            "label-name": "Title",
            "label-for": "title",
            "vmodel": _vm.form.title,
            "form-group-class": "form-group mb-3",
            "input-class": "custom-input",
            "rules": {
              required: true
            },
            "api-errors": _vm.apiErrors
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "title", $event);
            }
          }
        }), _c('div', {
          staticClass: "form-group mb-4 mt-3"
        }, [_c('validation-provider', {
          attrs: {
            "name": "Message",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('label', {
                attrs: {
                  "for": ""
                }
              }, [_vm._v("Description "), _c('span', {
                staticClass: "required-indc"
              }, [_vm._v("*")])]), _c('quill-editor', {
                attrs: {
                  "options": _vm.editorOption,
                  "height": "300"
                },
                model: {
                  value: _vm.form.description,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "description", $$v);
                  },
                  expression: "form.description"
                }
              }), _c('div', {
                staticClass: "invalid-block invalid-feedback"
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")])];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "col-md-12 mb-3"
        }, [_c('label', {
          staticClass: "label-txt up-load mt-2"
        }, [_vm._v("Upload Document ")]), _c('file-upload', {
          attrs: {
            "labelName": "Upload Files",
            "label-for": "files",
            "vmodel": _vm.form.files,
            "error": _vm.error,
            "remove": _vm.remove,
            "formGroupClass": "mt-4 mb-1 cursor-pointer"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "files", $event);
            },
            "update:error": function updateError($event) {
              _vm.error = $event;
            }
          }
        })], 1), _c('div', [_vm.isEdit && _vm.form.file_lists && _vm.form.file_lists.length ? _c('div', {
          staticClass: "my-2"
        }, [_c('ul', {
          staticClass: "upload-item"
        }, _vm._l(_vm.form.file_lists, function (file, index) {
          return _c('li', {
            key: index
          }, [_c('a', {
            staticClass: "clr-active",
            attrs: {
              "href": file.path,
              "target": "_blank"
            }
          }, [_vm._v(_vm._s(file.filename))]), _c('custom-button', {
            attrs: {
              "title": "Delete",
              "iconClass": "fas fa-trash-alt",
              "className": "ms-2"
            },
            on: {
              "onClicked": function onClicked($event) {
                return _vm.deleteFile(file.id, index);
              }
            }
          })], 1);
        }), 0)]) : _vm._e()]), _c('div', {
          staticClass: "form-group row"
        }, [_c('input-radio', {
          attrs: {
            "label": "Publication",
            "vmodel": _vm.form.is_published,
            "api-errors": _vm.apiErrors,
            "api-error-key": "is_published",
            "options": _vm.publicationOptions,
            "rules": {
              required: true
            },
            "form-group-class": "col-xxl-6 col-lg-6 col-md-6 mb-3"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "is_published", $event);
            }
          }
        }), _vm.form.is_published == '1' ? _c('input-date-picker', {
          attrs: {
            "vmodel": _vm.form.published_date,
            "rules": {
              required: true
            },
            "label": "Publication Date",
            "api-errors": _vm.apiErrors,
            "api-error-key": "published_date",
            "date-format": "MM/DD/YYYY",
            "disable-date-type": "disable-upto-yesterday",
            "form-group-class": "col-xxl-6 col-lg-6 col-md-6 mb-3"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "published_date", $event);
            }
          }
        }) : _vm._e()], 1), _c('div', {
          staticClass: "form-group row"
        }, [_c('input-multi-select-tags', {
          attrs: {
            "vmodel": _vm.form.publication_types,
            "label-name": "Whom To Publish",
            "options": _vm.userOptions.length ? _vm.userOptions.map(function (item) {
              return item.value;
            }) : [],
            "custom-label": function customLabel(option) {
              return _vm.userOptions.find(function (data) {
                return data.value == option;
              }).label;
            },
            "rules": {
              required: true
            },
            "form-group-class": "col-xxl-12 col-lg-12 col-md-12 mb-3",
            "placeholder": "Chose Whom To Publish",
            "label-key": "label",
            "track-by": "value",
            "options-list": _vm.userOptions,
            "api-errors": _vm.apiErrors,
            "api-error-key": "publication_types"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "publication_types", $event);
            }
          }
        }), _vm.isPublicationTypeValid ? _c('div', {
          staticClass: "mb-2"
        }, [_c('label', [_vm._v("Platform List")]), _c('select', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.platform,
            expression: "form.platform"
          }],
          staticClass: "price-option",
          class: [_vm.form.platform ? 'selected-color' : 'unselected-color'],
          on: {
            "change": function change($event) {
              var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
                return o.selected;
              }).map(function (o) {
                var val = "_value" in o ? o._value : o.value;
                return val;
              });

              _vm.$set(_vm.form, "platform", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
            }
          }
        }, [_c('option', {
          attrs: {
            "value": "",
            "disabled": ""
          }
        }, [_vm._v("Select Platform")]), _vm._l(_vm.platformList, function (platform, index) {
          return _c('option', {
            key: index,
            domProps: {
              "value": platform
            }
          }, [_vm._v(" " + _vm._s(platform) + " ")]);
        })], 2)]) : _vm._e()], 1), _c('div', {
          staticClass: "form-group row"
        }, [_vm.form.publication_types.includes('REP') ? _c('div', {
          staticClass: "col-md-12 mb-3"
        }, [_c('custom-button', {
          staticClass: "btn univ-btn",
          attrs: {
            "title": _vm.sendRepMessageSelectedReps ? "".concat(_vm.sendRepMessageSelectedReps.length, " Rep Selected") : "Select Rep",
            "haveText": ""
          },
          on: {
            "onClicked": _vm.openAddRepModal
          }
        })], 1) : _vm._e(), _vm.form.publication_types.includes('GROUP') ? _c('div', {
          staticClass: "col-md-12 mb-3"
        }, [_c('input-multi-select-tags', {
          attrs: {
            "vmodel": _vm.form.groups,
            "label-name": "Group List",
            "options": _vm.groupList && _vm.groupList.length ? _vm.groupList.map(function (item) {
              return item.id;
            }) : [],
            "custom-label": _vm.groupList && _vm.groupList.length ? function (option) {
              return _vm.groupList.find(function (data) {
                return data.id == option;
              }).name;
            } : function () {},
            "rules": {
              required: true
            },
            "placeholder": "Select Groups",
            "active-data": _vm.groupList,
            "options-limit": 5000,
            "is-loading": _vm.isGroupLoading,
            "options-list": _vm.groupList,
            "label-key": "name",
            "show-selections": true
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "groups", $event);
            },
            "search": function search(q) {
              return _vm.searchUser(q, 'group');
            }
          }
        })], 1) : _vm._e(), _vm.form.publication_types.includes('MEMBER') ? _c('div', {
          staticClass: "col-md-12 mb-3"
        }, [_c('input-multi-select-tags', {
          attrs: {
            "vmodel": _vm.form.members,
            "label-name": "Member List",
            "options": _vm.memberList && _vm.memberList.length ? _vm.memberList.map(function (item) {
              return item.id;
            }) : [],
            "custom-label": _vm.memberList && _vm.memberList.length ? function (option) {
              return _vm.memberList.find(function (data) {
                return data.id == option;
              }).name;
            } : function () {},
            "rules": {
              required: true
            },
            "placeholder": "Select Members",
            "active-data": _vm.memberList,
            "is-loading": _vm.isMemberLoading,
            "options-limit": 5000,
            "options-list": _vm.memberList,
            "label-key": "name",
            "show-selections": true
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "members", $event);
            },
            "search": function search(q) {
              return _vm.searchUser(q, 'member');
            }
          }
        })], 1) : _vm._e()]), _c('input-multi-select-tags', {
          attrs: {
            "vmodel": _vm.form.tags,
            "label-name": "Tags",
            "options": _vm.tags.length ? _vm.tags.map(function (item) {
              return item.id;
            }) : [],
            "custom-label": function customLabel(option) {
              return _vm.tags.find(function (data) {
                return data.id == option;
              }).name;
            },
            "rules": {
              required: true
            },
            "form-group-class": "form-group mb-3",
            "placeholder": "Select Or Add Tag",
            "label-key": "name",
            "track-by": "id",
            "options-list": _vm.tags,
            "is-taggable": true,
            "is-loading": _vm.isTagLoading,
            "api-errors": _vm.apiErrors
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "tags", $event);
            },
            "onAddTag": _vm.createTag
          }
        }), _c('div', {
          staticClass: "form-group mb-3 pt-3 text-center"
        }, [_c('button', {
          staticClass: "addBtn"
        }, [_vm._v(" " + _vm._s(_vm.isEdit ? "Update" : "Add") + " Knowledge Feed ")])])], 1)];
      }
    }])
  })], 1)]), _c('rep-tree-modal', {
    attrs: {
      "downlineList": _vm.downlineList
    },
    on: {
      "onCloseModal": _vm.closeRepTree
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }