var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.formGroupClass
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.label ? _vm.label.toLowerCase() : _vm.validationLabel.toLowerCase(),
      "rules": _vm.rules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_vm.label ? _c('label', [_vm._v(" " + _vm._s(_vm.label) + " "), _vm.rules.required ? _c('span', {
          staticClass: "required-indc"
        }, [_vm._v(" * ")]) : _vm._e()]) : _vm._e(), _c('date-picker', {
          attrs: {
            "aria-describedby": "input-feedback",
            "input-class": validationContext.errors[0] ? 'invalid-date-picker custom-input ' : 'custom-input ',
            "placeholder": _vm.computedPlaceholder,
            "format": _vm.dateFormat,
            "value-type": "format",
            "state": _vm.getValidationState(validationContext),
            "disabled-date": _vm.getDisabledDates
          },
          model: {
            value: _vm.computedInputData,
            callback: function callback($$v) {
              _vm.computedInputData = $$v;
            },
            expression: "computedInputData"
          }
        }), _c('div', {
          staticClass: "error-text"
        }, [_c('span', [_vm._v(_vm._s(validationContext.errors[0] ? validationContext.errors[0] : _vm.apiErrors && Object.keys(_vm.apiErrors).length ? _vm.apiErrors[_vm.apiErrorKey ? _vm.apiErrorKey : _vm.label.toString().toLowerCase()][0] : ""))])])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }