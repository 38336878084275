var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "k-feed-popup"
  }, [_c('b-modal', {
    attrs: {
      "id": "knowledgeFeedUser",
      "centered": "",
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "k-feed-popup-header text-center"
        }, [_vm.userRow ? _c('h4', {
          staticClass: "clr-theme"
        }, [_vm._v(_vm._s(_vm.userRow.title))]) : _vm._e(), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_vm.userRow ? _c('p', {
    staticClass: "text-dark text-center"
  }, [_vm._v(" Selected " + _vm._s(_vm.userType.toLowerCase()) + " for this knowledge feed ")]) : _vm._e(), _vm.userList && _vm.userList.records ? _c('div', {
    staticClass: "row"
  }, _vm._l(_vm.userList.records, function (item, i) {
    return _c('div', {
      key: i,
      staticClass: "col-3 mb-3"
    }, [_c('span', {
      staticClass: "d-inline-block rounded-cover"
    }, [_vm._v(_vm._s("".concat(item.name, " ").concat(item.code ? "(".concat(item.code, ")") : '')))])]);
  }), 0) : _vm._e(), _vm._t("pagination")], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }