var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.formGroupClass
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.labelName ? _vm.labelName.toLowerCase() : _vm.label.toLowerCase(),
      "rules": _vm.rules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_vm.label ? _c('label', [_vm._v(" " + _vm._s(_vm.label) + " "), _vm.rules.required ? _c('span', {
          staticClass: "required-indc"
        }, [_vm._v(" * ")]) : _vm._e()]) : _vm._e(), _vm._l(_vm.options, function (option, index) {
          var _domProps;

          return _c('div', {
            key: index,
            class: _vm.divClass
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.computedVmodel,
              expression: "computedVmodel"
            }],
            staticClass: "form-check-input",
            attrs: {
              "type": "radio",
              "id": "label-".concat(index, "-").concat(_vm.label),
              "name": _vm.label
            },
            domProps: (_domProps = {
              "value": option.value,
              "checked": _vm.vmodel == option.value ? true : false
            }, _domProps["checked"] = _vm._q(_vm.computedVmodel, option.value), _domProps),
            on: {
              "input": function input($event) {
                return _vm.inputHandler(option.value);
              },
              "change": function change($event) {
                _vm.computedVmodel = option.value;
              }
            }
          }), _c('label', {
            staticClass: "form-check-label",
            attrs: {
              "for": "label-".concat(index, "-").concat(_vm.label)
            }
          }, [_c('span'), _vm._v(" " + _vm._s(option.label) + " ")])]);
        }), _c('div', {
          staticClass: "error-text"
        }, [_c('span', [_vm._v(_vm._s(_vm.errorField ? _vm.errorField[0] : validationContext.errors[0]))])])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }