<template>
  <div :class="formGroupClass">
    <validation-provider
      :name="labelName ? labelName.toLowerCase() : label.toLowerCase()"
      :rules="rules"
      v-slot="validationContext"
    >
      <label v-if="label">
        {{ label }}
        <span class="required-indc" v-if="rules.required"> * </span>
      </label>
      <div :class="divClass" v-for="(option, index) in options" :key="index">
        <input
          :value="option.value"
          v-model="computedVmodel"
          type="radio"
          :id="`label-${index}-${label}`"
          :name="label"
          class="form-check-input"
          :checked="vmodel == option.value ? true : false"
          @input="inputHandler(option.value)"
        />
        <label :for="`label-${index}-${label}`" class="form-check-label"
          ><span></span> {{ option.label }}
        </label>
      </div>
      <div class="error-text">
        <span>{{
          errorField ? errorField[0] : validationContext.errors[0]
        }}</span>
      </div>
    </validation-provider>
  </div>
</template>
<script>
export default {
  name: "InputRadio",
  props: {
    apiErrors: {
      // Object with server side error flags
      required: false,
      default: () => {},
    },
    apiErrorKey: {
      // key pointing to particular entry in apiError Object
      required: false,
      default: "",
    },
    label: {
      required: false,
    },
    vmodel: {
      required: true,
    },
    formGroupClass: {
      required: false,
      default: "form-group row",
    },
    rules: {
      type: Object,
      required: false,
      default: null,
    },
    divClass: {
      required: false,
      default: "form-check form-check-inline mt-2",
    },
    options: {
      required: true,
    },
    labelName: {
      type: String,
      required: false,
    },
    errorKey: {
      // key name in apiError Object
      required: false,
      default: "",
    },
  },
  computed: {
    computedVmodel: {
      get: function () {
        return this.vmodel;
      },
      set: function (value) {
        this.$emit("update:vmodel", value ? value : "");
      },
    },
    errorField() {
      return this.apiErrors
        ? this.apiErrors[this.apiErrorKey]
          ? this.apiErrors[this.apiErrorKey]
          : null
        : null;
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      if (this.rules) {
        return dirty || validated ? valid : null;
      }
      return null;
    },
    inputHandler(value) {
      this.$emit("inputHandler", value);
    },
  },
};
</script>

<style>
.form-check-inline input {
  box-shadow: none !important;
}
.error-text {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
</style>
