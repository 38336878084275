<template>
  <div class="k-feed-popup">
    <b-modal id="knowledgeFeedUser" centered size="lg" no-close-on-backdrop>
      <template #modal-header>
        <div class="k-feed-popup-header text-center">
          <h4 class="clr-theme" v-if="userRow">{{ userRow.title }}</h4>
          <b-button variant="close-btn" @click="closeModal">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <p class="text-dark text-center" v-if="userRow">
          Selected {{userType.toLowerCase()}} for this knowledge feed
        </p>
        <div class="row" v-if="userList && userList.records">
          <div
            class="col-3 mb-3"
            v-for="(item, i) in userList.records"
            :key="i"
          >
            <span class="d-inline-block rounded-cover">{{
              `${item.name} ${item.code ? `(${item.code})` : ''}`
            }}</span>
          </div>
        </div>
        <slot name="pagination" />
      </b-container>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    userList: {
      required: true,
      default: null,
    },
    userType: {
      required: true,
      type: String,
    },
    userRow:{
      required:true,
      default:null
    }
  },
  data: () => ({
    show: false,
  }),
  methods: {
    closeModal() {
      this.$emit("update:userList", null);
      this.$emit("update:userRow", null);
      this.$emit("update:userType", "");
      this.$bvModal.hide("knowledgeFeedUser");
    },
  },
};
</script>
<style>
.modal-header {
  justify-content: center;
  border: 0;
  padding: 25px 0;
  position: relative;
}
.modal-footer {
  display: none;
}
.k-feed-popup-header button {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #fff;
  color: #000;
  border: 0;
  box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
  border-radius: 60px;
  font-size: 18px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.modal-content {
  border: 0;
  border-radius: 15px;
}
.k-feed-popup-header h4 {
  font-size: 20px;
  color: #2c3f58;
  font-weight: 600;
  margin: 0;
}
#knowledgeFeedUser .modal-header {
  padding-bottom: 10px !important;
}
</style>
<style scoped>
.clr-theme {
  color: #03c3ec !important;
}
.rounded-cover {
  background: #eee;
  border-radius: 12px;
  padding: 6px 8px;
  font-size: 14px;
  text-align: center;
  word-break: break-word;
}
</style>
