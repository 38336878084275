<template>
  <div class="page-wrapper k-feed-wrapper">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title">
        <div class="titleBtn">
          <h1>Manage Knowledge Feeds</h1>
          <button class="k-feed-add-btn" @click="openForm">
            <i aria-hidden="true" class="fa fa-plus"></i> Add Knowledge Feed
          </button>
        </div>
      </div>
      <div class="k-feed-card">
        <div class="filter-field text-end mb-3">
          <input
            type="text"
            placeholder="Search (by title , tag)"
            class="custom-input"
            v-model="filters.query"
            @keyup="filterList"
          />
        </div>
        <knowledge-feed-list
          :rows.sync="rows"
          @onSuccess="getKnowledgeFeed(filters)"
          @onEdit="onEdit"
          @openUserModal="openUserModal"
        />
        <pagination :rows.sync="rows" />
      </div>
    </div>
    <knowledge-feed-form
      @onSuccess="getKnowledgeFeed(filters)"
      :row.sync="row"
      :is-edit.sync="isEdit"
    />
    <knowledge-feed-user
      :user-row.sync="userRow"
      :user-list.sync="userList"
      :user-type.sync="userType"
    >
      <template #pagination>
        <pagination :rows.sync="userList" v-if="userList" class="mt-2" />
      </template>
    </knowledge-feed-user>
  </div>
</template>
<script>
import KnowledgeFeedForm from "./partials/KnowledgeFeedForm.vue";
import KnowledgeFeedList from "./partials/KnowledgeFeedList.vue";
import KnowledgeFeedUser from "./partials/KnowledgeFeedUser";

import Api from "@/resource/Api";
import helper from "@/resource/Helper";

export default {
  name: "KnowledgeFeed",
  components: {
    KnowledgeFeedForm,
    KnowledgeFeedList,
    KnowledgeFeedUser,
  },
  data: () => ({
    breadcrumbItems: [{ title: "Knowledge Feeds", active: true }],
    rows: [],
    filters: {
      query: "",
    },
    row: {},
    userList: [],
    userType: "",
    isEdit: false,
    userRow:null,
  }),
  methods: {
    openForm() {
      this.row = {};
      this.$bvModal.show("knowledgeFeed");
    },
    getKnowledgeFeed(filters = null) {
      let loader = !filters ? this.$loading.show() : "";
      let url = "v2/knowledge-feed/list";
      if (filters) {
        let filterUrl = helper.objToUrlParams(filters);
        url = `${url}?${filterUrl}`;
        if (this.rows && this.rows.current_page) {
          url = url + `&page=${this.rows.current_page}`;
        }
      }
      Api.get(url)
        .then((res) => {
          this.rows = res.data;
        })
        .catch(() => {
          this.rows = [];
        })
        .finally(() => {
          loader ? loader.hide() : "";
        });
    },
    getDetail(row) {
      let loader = this.$loading.show();
      Api.get(`v2/knowledge-feed/${row.id}/show`)
        .then((res) => {
          this.row = res.data.data;
        })
        .catch(() => {
          this.row = {};
        })
        .finally(() => {
          loader.hide();
        });
    },
    filterList() {
      this.getKnowledgeFeed(this.filters);
    },
    onEdit(row) {
      this.isEdit = true;
      this.getDetail(row);
      this.$bvModal.show("knowledgeFeed");
    },
    openUserModal(row, type) {
      this.userRow = row;
      this.userType = type;
      this.$bvModal.show("knowledgeFeedUser");
      this.getUserList(row.id, type.toLowerCase());
    },
    getUserList(id, type) {
      let loader = this.$loading.show();
      const url = `v2/knowledge-feed/${id}/${type}-list`;
      Api.get(url)
        .then((res) => {
          this.userList = res.data;
        })
        .catch(() => {
          this.userList = [];
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  created() {
    this.getKnowledgeFeed();
  },
};
</script>
<style src="@/assets/css/knowledgefeed.css"></style>