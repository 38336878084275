<template>
  <div class="k-feed-popup">
    <b-modal
      id="publishModal"
      centered
      size="sm"
      v-model="show"
      @shown="onModalShow"
      no-close-on-backdrop
    >
      <template #modal-header>
        <div class="k-feed-popup-header text-center">
          <h4 class="clr-theme">{{ row.title }}</h4>
          <b-button variant="close-btn" @click="closeModal">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div class="k-feed-popup-form">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">
              <input-date-picker
                :vmodel.sync="form.published_date"
                :rules="{ required: true }"
                form-group-class="form-group"
                label=" Choose Publication Date"
                :api-errors="apiErrors"
                api-error-key="published_date"
                disable-date-type="disable-upto-yesterday"
                placeholder="Choose Publication Date"
              />
              <div class="form-group mb-3 pt-3 text-center">
                <button class="univ-btn">Publish Knowledge Feed</button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import InputDatePicker from "@/components/form/InputDatePicker.vue";
import moment from "moment";
import Api from "@/resource/Api";
const form = {
  published_date: "",
};

export default {
  components: {
    InputDatePicker,
  },
  props: {
    row: {
      required: true,
      default: () => {},
    },
  },
  data: () => ({
    show: false,
    form: { ...form },
    apiErrors: {},
  }),
  methods: {
    closeModal() {
      this.form = { ...form };
      this.$bvModal.hide("publishModal");
    },
    getFormattedDate(date, format) {
      return moment(date).format(format);
    },
    errorResponse(error) {
      if (error.response.status == 422) {
        this.apiErrors = { ...error.response.data.errors };
        setTimeout(() => {
          this.apiErrors = {};
        }, 5000);
      } else {
        this.$notify(error.response.data.message, "error");
      }
    },

    submit() {
      let loader = this.$loading.show();
      let formData = {
        id: this.row.id,
        is_published: true,
        published_date: this.getFormattedDate(
          this.form.published_date,
          "YYYY-MM-DD"
        ),
      };
      Api.put("v2/knowledge-feed/publish", formData)
        .then((res) => {
          this.$emit("onSuccess");
          this.$notify(res.data.message);
          this.closeModal();
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          loader.hide();
        });
    },
    onModalShow() {
      this.form = { ...form };
    },
  },
};
</script>
<style>
.mx-datepicker {
  width: 100%;
}

.modal-header {
  justify-content: center;
  border: 0;
  padding: 25px 0;
  position: relative;
}
.modal-footer {
  display: none;
}
.k-feed-popup-header button {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #fff;
  color: #000;
  border: 0;
  box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
  border-radius: 60px;
  font-size: 18px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.modal-content {
  border: 0;
  border-radius: 15px;
}
.k-feed-popup-header h4 {
  font-size: 20px;
  color: #2c3f58;
  font-weight: 600;
  margin: 0;
}

.modal-sm {
  min-width: 360px;
}
#publishModal .modal-body {
  padding-top: 0 !important;
}
.univ-btn {
  background: linear-gradient(#0cf, #1597b8 49%, #0567bc);
  border-radius: 6px;
  color: #fff;
  outline: none;
  border: 0;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  margin-top: 10px;
  transition: all ease 0.6s;
}
</style>

<style scoped>
.clr-theme {
  color: #03c3ec !important;
}
</style>